import n from "lodash/reject";
import r from "lodash/isPlainObject";
import t from "lodash/omitBy";
import e from "lodash/forEach";
import u from "lodash/map";
import o from "lodash/mapValues";
import i from "lodash/omit";
function c(n) {
  return function (n) {
    return null !== n && !(n instanceof RegExp) && !(n instanceof ArrayBuffer) && (Array.isArray(n) || "object" == typeof n);
  }(n) && !Object.isFrozen(n);
}
function f(n) {
  return Object.freeze(n), Object.keys(n).forEach(function (r) {
    var t = n[r];
    c(t) && f(t);
  }), n;
}
function a(n) {
  return "undefined" == typeof process || "production" === process.env.NODE_ENV || "dangerously_never_freeze" === process.env.UPDEEP_MODE || c(n) && f(n), n;
}
function l(n) {
  var r = a(n);
  return function () {
    return r;
  };
}
function s(r) {
  return Array.isArray(r) ? r : n(("" + r).split("."), function (n) {
    return !n;
  });
}
var p = "@@updeep/placeholder";
function y(n, r) {
  var t = n.length;
  for (t > r && (t = r); n[t - 1] === p;) t -= 1;
  return t;
}
function h(n) {
  return function r(t) {
    var e = [].slice.call(arguments, 1),
      u = e[0],
      o = e[1];
    return y(arguments) >= 1 ? n(t, u, o) : r;
  };
}
function v(n) {
  return function r(t, e) {
    var u = [].slice.call(arguments, 2),
      o = u[0],
      i = u[1],
      c = y(arguments, 2);
    if (e === p || o === p || i === p) throw new Error("Can only use placeholder on first argument of this function.");
    return c >= 2 ? t === p ? h(function (r, t, u) {
      return n(r, e, t, u);
    }) : n(t, e, o, i) : 1 === c ? h(function (r, e, u) {
      return n(t, r, e, u);
    }) : r;
  };
}
function d(n) {
  return function r(t, e, u) {
    var o = [].slice.call(arguments, 3),
      i = o[0],
      c = o[1],
      f = y(arguments, 3);
    if (u === p || i === p || c === p) throw new Error("Can only use placeholder on first or second argument of this function.");
    return f >= 3 ? t === p ? e === p ? v(function (r, t, e, o) {
      return n(r, t, u, e, o);
    }) : h(function (r, t, o) {
      return n(r, e, u, t, o);
    }) : e === p ? h(function (r, e, o) {
      return n(t, r, u, e, o);
    }) : n(t, e, u, i, c) : 2 === f ? t === p ? v(function (r, t, u, o) {
      return n(r, e, t, u, o);
    }) : h(function (r, u, o) {
      return n(t, e, r, u, o);
    }) : 1 === f ? v(function (r, e, u, o) {
      return n(t, r, e, u, o);
    }) : r;
  };
}
function m(n) {
  return function r(t, e, u, o) {
    var i = [].slice.call(arguments, 4),
      c = i[0],
      f = i[1],
      a = y(arguments, 4);
    if (o === p || c === p || f === p) throw new Error("Can only use placeholder on first, second or third argument of this function.");
    return a >= 4 ? t === p ? e === p ? u === p ? d(function (r, t, e, u, i) {
      return n(r, t, e, o, u, i);
    }) : v(function (r, t, e, i) {
      return n(r, t, u, o, e, i);
    }) : u === p ? v(function (r, t, u, i) {
      return n(r, e, t, o, u, i);
    }) : h(function (r, t, i) {
      return n(r, e, u, o, t, i);
    }) : e === p ? u === p ? v(function (r, e, u, i) {
      return n(t, r, e, o, u, i);
    }) : h(function (r, e, i) {
      return n(t, r, u, o, e, i);
    }) : u === p ? h(function (r, u, i) {
      return n(t, e, r, o, u, i);
    }) : n(t, e, u, o, c, f) : 3 === a ? t === p ? e === p ? d(function (r, t, e, o, i) {
      return n(r, t, u, e, o, i);
    }) : v(function (r, t, o, i) {
      return n(r, e, u, t, o, i);
    }) : e === p ? v(function (r, e, o, i) {
      return n(t, r, u, e, o, i);
    }) : h(function (r, o, i) {
      return n(t, e, u, r, o, i);
    }) : 2 === a ? t === p ? d(function (r, t, u, o, i) {
      return n(r, e, t, u, o, i);
    }) : v(function (r, u, o, i) {
      return n(t, e, r, u, o, i);
    }) : 1 === a ? d(function (r, e, u, o, i) {
      return n(t, r, e, u, o, i);
    }) : r;
  };
}
function g(n, r) {
  return void 0 === r && (r = n.length), [n, h, v, d, m][r](n);
}
var O = g(function (n, r, t) {
  for (var e = s(n), u = t, o = 0; o < e.length; o += 1) {
    if (void 0 === u) return !1;
    u = u[e[o]];
  }
  return "function" == typeof r ? r(u) : r === u;
});
function j() {
  return j = Object.assign ? Object.assign.bind() : function (n) {
    for (var r = 1; r < arguments.length; r++) {
      var t = arguments[r];
      for (var e in t) Object.prototype.hasOwnProperty.call(t, e) && (n[e] = t[e]);
    }
    return n;
  }, j.apply(this, arguments);
}
function b(n, r) {
  return void 0 === r && (r = n.length), g(function () {
    return a(n.apply(void 0, [].slice.call(arguments)));
  }, r);
}
var A = {
    __omitted: !0
  },
  E = l(A),
  w = b(function n(e, u) {
    if ("function" == typeof e) return e.apply(void 0, [u].concat([].slice.call(arguments, 2)));
    if (!r(e)) return e;
    var o = null == u ? {} : u,
      i = function (r, t) {
        return function (n, r, t) {
          return Object.keys(n).reduce(function (t, e) {
            return r(t, n[e], e);
          }, {});
        }(r, function (r, e, u) {
          var o = e;
          return Array.isArray(e) || null === e || "object" != typeof e ? "function" == typeof e && (o = e(t[u])) : o = n(e, t[u]), function (n, r, t) {
            return n === A ? t.hasOwnProperty(r) : t[r] !== n;
          }(o, u, t) && (r[u] = o), r;
        });
      }(e, o);
    return function (n) {
      return !Object.keys(n).length;
    }(i) ? o : Array.isArray(o) ? function (n, r) {
      var t = [].concat(r);
      return Object.keys(n).forEach(function (r) {
        t[r] = n[r];
      }), t;
    }(i, o).filter(function (n) {
      return n !== A;
    }) : t(j({}, o, i), function (n) {
      return n === A;
    });
  }, 2),
  _ = b(function (n, r, t, e) {
    var u = "function" == typeof n ? n(e) : n;
    return w(u ? r : t, e);
  }),
  P = g(function (n, r, t) {
    return _(n, r, function (n) {
      return n;
    }, t);
  }),
  k = b(function (n, r) {
    var t = "function" == typeof n ? n : w(n),
      i = (Array.isArray(r) ? u : o)(r, t);
    return function (n, r) {
      var t = !0;
      return e(r, function (r, e) {
        if (r !== n[e]) return t = !1, !1;
      }), t;
    }(r, i) ? r : i;
  });
function z(n) {
  return Array.isArray(n) ? n.length : Object.keys(n).length;
}
var D = b(function (n, r) {
    var t = i(r, n);
    return null == r || z(t) !== z(r) ? t : r;
  }),
  B = b(function (n, r) {
    var e = t(r, n);
    return null == r || z(e) !== z(r) ? e : r;
  }),
  C = b(function (r, t) {
    var e = n(t, r);
    return t.length === e.length ? t : e;
  }),
  x = "*";
function N(n, r) {
  var t;
  return r === x ? function (r) {
    var t;
    return Object.prototype.hasOwnProperty.call(r, x) ? w(((t = {})[x] = n, t), r) : k(n, r);
  } : ((t = {})[r] = n, t);
}
var R = g(function (n, r, t) {
    var e = s(n).reduceRight(N, r);
    return w(e, t);
  }),
  V = g(function (n, r, t) {
    return w(r, void 0 === t ? n : t);
  }),
  F = w;
F._ = p, F.constant = l, F.if = P, F.ifElse = _, F.is = O, F.freeze = a, F.map = k, F.omit = D, F.omitBy = B, F.reject = C, F.update = w, F.updateIn = R, F.omitted = E, F.withDefault = V;
export { F as default };
